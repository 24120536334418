export const DEV_ENV = false;
export const LOG = false;
export const STAFF_TOKEN_KEY = 'STAFF_TOKEN_KEY';
export const SUBDOMAIN_KEY = 'SUBDOMAIN_KEY';
export const BROKEN_DATE_PCIKER_FORMATTING = false;

/*
 * New Variables to be used in conjunction with AxiosHelpers
 */
export const PROTOCOL =  DEV_ENV ? 'http' : 'https';
export const PROD_DOMAIN = 'api.englishvoice.in';
export const DEV_DOMAIN = 'english-voice';
export const ROOT_DOMAIN = DEV_ENV ? DEV_DOMAIN : PROD_DOMAIN;
export const BYPASS_SUBDOMAIN = false;

/**
 * -----------------------------------------
 * DEPRECATED VARAIBLES.
 * NOT USED WITH AxiosHelpers. TO BE AVOIDED
 */
const PROD_URL = () => {
  const subdomain = localStorage.getItem(SUBDOMAIN_KEY);
  return `https://${subdomain}.api.englishvoice.in`;
};
const DEV_URL = () => {
  const subdomain = localStorage.getItem(SUBDOMAIN_KEY);
  return `http://${subdomain}.englishvoice`;
}
// function must be calles instead of passed value
// NOTE: This is so stupid. Function could have been called here itself and
// been reduced to a constant.
// TODO: Refactor across all APIs to remove unnecessary function calls
export const BASE_URL = DEV_ENV ? DEV_URL : PROD_URL;
export const DOMAIN_URL = DEV_ENV ? 'http://englishvoice' : 'https://api.englishvoice.in';
/**
 * DEPRECATED VARIABLES END
 * -----------------------------------------
 */

export const DEBOUNCE_DELAY_VALUE = 750;
export const OTP_DISABLE_THRESHOLD = 0;
export const DEFAULT_SETTINGS = {
  default_password: 'password',
  organization_name: 'English Voice',
  otp_reset_enable: true,
};

export const dropdownHeightModifier = {
  setMaxHeight: {
    enabled: true,
    fn: (data) => {
      return {
        ...data,
        styles: {
          ...data.styles,
          overflow: 'auto',
          maxHeight: '240px',
        },
      };
    },
  },
};

export const STAFF_TYPES = [ 'teaching', 'non_teaching', 'support' ];
export const FEEDBACK_TYPES = [ 'Enquiry', 'Feedback', 'Bug Report' ];

export const fakeData = [
    {name: 'Page A', uv: 4000, pv: 2400, amt: 2400},
    {name: 'Page B', uv: 3000, pv: 1398, amt: 2210},
    {name: 'Page C', uv: 2000, pv: 9800, amt: 2290},
    {name: 'Page D', uv: 2780, pv: 3908, amt: 2000},
    {name: 'Page E', uv: 1890, pv: 4800, amt: 2181},
    {name: 'Page F', uv: 2390, pv: 3800, amt: 2500},
    {name: 'Page G', uv: 3490, pv: 4300, amt: 2100},
    {name: 'Page C', uv: 2000, pv: 6800, amt: 2290},
    {name: 'Page D', uv: 4780, pv: 7908, amt: 2000},
    {name: 'Page E', uv: 2890, pv: 9800, amt: 2181},
    {name: 'Page F', uv: 1390, pv: 3800, amt: 1500},
    {name: 'Page G', uv: 3490, pv: 4300, amt: 2100},
];

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const FEEDBACK_SOURCES = {
  student: 'App',
  student_web: 'Web Panel',
};

export const STREAM_STATUSES = [ 'Scheduled', 'Ongoing', 'Archived' ];
export const CONFERENCE_STATUSES = [ 'Scheduled', 'Ongoing', 'Archived' ];
export const CONFERENCE_PROVIDERS = {
  ZOOM: 'ZOOM',
};
export const PASSWORD_MINIMUM_LENGTH = 6;
export const LOW_SMS_THRESHOLD = 100;

export const DAYS = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thurs',
  'Fri',
  'Sat',
];
