export const MainNav = [
    {
        icon: 'lnr-chart-bars',
        label: 'Dashboard',
        to: '#/dashboard',
    },
    {
        icon: 'pe-7s-notebook',
        label: 'Assignments',
        to: '#/assignments',
    },
    {
        icon: 'pe-7s-notebook',
        label: 'Study Material',
        to: '#/study-material',
    },
    {
        icon: 'lnr-film-play',
        label: 'Lecture Streams',
        to: '#/streams',
    },
    {
        icon: 'lnr-laptop-phone',
        label: 'Online Classes',
        to: '#/conferences',
    },
    {
        icon: 'lnr-envelope',
        label: 'Video Repository',
        to: '#/video',
    },
    {
        icon: 'lnr-envelope',
        label: 'Quizzes',
        to: '#/quiz',
    },
    {
        icon: 'lnr-cog',
        label: 'Submit A Doubt',
        to: '#/feedback',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Profile',
        to: '#/settings',
    }
];
