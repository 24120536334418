import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import UserBox from './Components/UserBox';
import { SUBDOMAIN_KEY } from '../../config/constants';


class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };
    }
    render() {
        return (
            <Fragment>
                <div class="middle-sidebar-header bg-white mont-font">
                    <button class="header-menu"></button>
                    <div>
                        Username : <span className="text-danger">{this.props.StaffAuth.username}</span>
                        &nbsp;&nbsp; Organization Code : <span className="text-danger">{localStorage.getItem(SUBDOMAIN_KEY)}</span>
                    </div>
                    <div className="d-flex ml-auto right-menu-icon">
                        <UserBox type={this.props.type} />
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
    StaffAuth: state.StaffAuth,
});

const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Header);