import { loginApi, refreshTokenApi, logoutApi } from '../api/auth_api';
import { STAFF_TOKEN_KEY, LOG } from '../config/constants';

export const STAFF_LOGIN = 'STAFF_LOGIN';
export const STAFF_REFRESH_TOKEN = 'STAFF_REFRESH_TOKEN';
export const STAFF_REFRESH_TOKEN_FAILED = 'STAFF_REFRESH_TOKEN_FAILED';
export const STAFF_LOGGING_IN = 'STAFF_LOGGING_IN';
export const STAFF_LOGIN_ERROR = 'STAFF_LOGIN_ERROR';
export const STAFF_LOGOUT = 'STAFF_LOGOUT';

export const loginUser = async (dispatch, payload) => {
  dispatch({ type: STAFF_LOGGING_IN });
  console.log(payload);
  let res;
  try {
    res = await loginApi(payload.username, payload.password);
  } catch(err) {
    if (LOG) {
      console.log(err);
    }
    dispatch({ type: STAFF_LOGIN_ERROR });
  }
  if (res) {
    const { token, email, name, organization_name, domain, mobile, grade, guardian_mobile, guardian_name, groups, logo_show } = res;
    localStorage.setItem(STAFF_TOKEN_KEY, token);
    localStorage.setItem("StudentGrade", grade);
    dispatch({ type: STAFF_LOGIN, payload: { name, username: email, token, organization_name, domain, mobile, groups, grade, guardian_mobile, guardian_name, logo_show }});
  }
};

export const refreshToken = async (dispatch) => {
  dispatch({ type: STAFF_REFRESH_TOKEN });
  let res;
  try {
    res = await refreshTokenApi();
  } catch(e) {
    dispatch({ type: STAFF_REFRESH_TOKEN_FAILED });
  }
  if (res) {
    const { token, email, name, organization_name, domain, mobile, grade, guardian_mobile, guardian_name, groups, logo_show } = res;
    localStorage.setItem(STAFF_TOKEN_KEY, token);
    localStorage.setItem("StudentGrade", grade);
    dispatch({ type: STAFF_LOGIN, payload: { name, username: email, token, organization_name, domain, mobile, grade, guardian_mobile, guardian_name, groups, logo_show }});
  }
};

export const logoutStaff = async (dispatch) => {
  dispatch({ type: STAFF_LOGOUT });
  await logoutApi();
  localStorage.setItem(STAFF_TOKEN_KEY, '');
};

export default function reducer(state = {
  loggedIn: false,
  loggingIn: false,
  loginError: false,
  refreshingToken: false,
  name: '',
  username: '',
  token: '',
}, action) {
  switch (action.type) {
    case STAFF_LOGIN:
      return {
        ...state,
        loggedIn: true,
        loggingIn: false,
        loginError: false,
        refreshingToken: false,
        name: action.payload.name,
        token: action.payload.token,
        username: action.payload.username,
        domain: action.payload.domain,
        organization_name: action.payload.organization_name,
        mobile: action.payload.mobile,
        grade: action.payload.grade,
        guardian_mobile: action.payload.guardian_mobile,
        guardian_name: action.payload.guardian_name,
        groups: action.payload.groups,
        logo_show: action.payload.logo_show,
      };
    case STAFF_LOGGING_IN:
      return {
        ...state,
        loggedIn: false,
        loggingIn: true,
        loginError: false,
        refreshingToken: false,
      };
    case STAFF_LOGIN_ERROR:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        loginError: true,
        refreshingToken: false,
      };
    case STAFF_REFRESH_TOKEN:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        loginError: false,
        refreshingToken: true,
      };
    case STAFF_REFRESH_TOKEN_FAILED:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        loginError: false,
        refreshingToken: false,
      };
    case STAFF_LOGOUT:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        loginError: false,
        refreshingToken: false,
      };
    default:
      return state;
  }
}
