import { POST } from '../utilities/AxiosHelpers';

export function loginApi(username, password) {
  const formData = new FormData();
  formData.append('email', username);
  formData.append('password', password);
  return POST('login', formData);
}

export function refreshTokenApi() {
  return POST('refresh');
}

export function logoutApi() {
  return POST('logout');
}

export const requestOtpApi = (data) => {
  return POST('otp/send', data);
};

export const verifyOtpApi = (data) => {
  return POST('otp/verify', data);
};

export const resetPasswordApi = (data) => {
  return POST('forgot/change_password', data);
};

export const updatePasswordApi = (data) => {
  return POST('update_password', data);
};
